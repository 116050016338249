import React from 'react';

import Badge from '@material-ui/core/Badge';

import { SdmTypography } from './../../../../components';

import { theme } from './../../../../theme';
import { styled } from '@material-ui/core/styles';

const TopContainer = styled('div')({
  display: 'flex',
  marginTop: 15,
});

const NameContainer = styled('div')({
  marginLeft: 10,
});

const StyledHR = styled('hr')({
  opacity: 0.22,
  borderTop: `1px solid ${theme.colors.greyScale.light}`,
});

const CaptionTypography = styled(SdmTypography)(
  ({ coloroverride, fontweightoverride }) => ({
    fontSize: '0.875rem',
    color: coloroverride ? coloroverride : theme.colors.greyScale.white,
    fontWeight: fontweightoverride ? fontweightoverride : 'normal',
  })
);

const DescriptionTypography = styled(SdmTypography)({});

export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    color: 'transparent',
    backgroundColor: theme.colors.primary.yellow,
    left: 0,
    height: 10,
    minWidth: 10,
    borderRadius: 5,
    padding: 0,
    transform: 'translate(5px, -6px)',
  },
});

const Question = ({ i18n, time, question, givenName, familyName, isMine }) => {
  return (
    <>
      <TopContainer>
        <CaptionTypography margin="0">{time}</CaptionTypography>
        <NameContainer>
          {!isMine ? (
            <CaptionTypography margin="0">
              {`${givenName || ''} ${familyName || ''}`}
            </CaptionTypography>
          ) : (
            <CaptionTypography
              margin="0"
              coloroverride={theme.colors.primary.yellow}
              fontweightoverride="bold"
            >
              {i18n.mine.label}
              <StyledBadge badgeContent={' '} />
            </CaptionTypography>
          )}
        </NameContainer>
      </TopContainer>
      <DescriptionTypography margin="0">{question}</DescriptionTypography>
      <StyledHR />
    </>
  );
};

export { Question };
