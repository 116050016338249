import React from 'react';
import { useIntl } from 'react-intl';
import { SdmTypography } from '../../../components';
import translation from '../../../utils/translation';

const NoAuth = () => {
  const intl = useIntl();
  const i18n = translation.webinar.session(intl);

  return (
    <>
      <SdmTypography
        style={{
          font: 'normal normal 300 31px/22px Poppins',
          lineHeight: 1.1,
        }}
      >
        {i18n.noAuth.userAlredyLogged.text.label}
      </SdmTypography>
    </>
  );
};

export { NoAuth };
