import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';

import Reward from 'react-rewards';

import { SdmDialog, CloseIcon } from './../../components';

import { theme } from './../../theme';
import { styled } from '@material-ui/core/styles';

import { usePubNub } from 'pubnub-react';

import { AskAQuestion } from './AskAQuestion';
import { Survey } from './Survey';
import { LeftButtons } from './LeftButtons';
import { RightColumn } from './RightColumn';
import { RightColumnTabs } from './RightColumnTabs';
import { TopControls } from './TopControls';

import usePageVisibility from './../../utils/usePageVisibility';

import {
  useChat,
  constants,
  extractUserInfo,
  useReactions,
  fetchTC,
} from './../../utils';

import { AuthContext, LoaderContext } from './../../contexts';

const CustomDialog = styled(SdmDialog)({
  '& .MuiDialog-paperFullScreen': {
    background:
      'rgba(0,0,0, 0.4) linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box',
    opacity: 1,
    backdropFilter: 'blur(22px)',
    WebkitBackdropFilter: 'blur(22px)',
  },
});

const CloseIconDialog = styled(CloseIcon)({
  color: theme.colors.greyScale.white,
  stroke: theme.colors.greyScale.white,
  cursor: 'pointer',
  position: 'absolute',
  right: 20,
  top: 35,
});

const defaultConfig = {
  fakingRequest: false,
  angle: 90,
  decay: 0.95,
  spread: 35,
  startVelocity: 17,
  elementCount: 15,
  elementSize: 20,
  lifetime: 40,
  zIndex: 10,
  springAnimation: true,
  rewardPunish: 'reward',
  type: 'emoji',
};

const Overlay = React.memo(
  ({ msgChannel, uuid, session, hasBeenBanned, eventId, showControls }) => {
    const { user } = useContext(AuthContext);
    const { setLoaderActive } = useContext(LoaderContext);

    const showLoader = () => setLoaderActive(true);
    const hideLoader = () => setLoaderActive(false);

    const [openRightColumn, setOpenRightColumn] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [lastButtonSelected, setLastButtonSelected] = useState();
    const [surveyAnswerSelected, setSuveryAnswerSelected] = useState(null);
    const [isOpenReactions, setIsOpenReactions] = useState(false);
    const [reactionConfig, setReactionConfig] = useState(defaultConfig);
    const [userMetadata, setUserMetadata] = useState();
    const [isUserBanned, setIsUserBanned] = useState(hasBeenBanned);
    const [isTabActive, setIsTabActive] = useState(true);
    const rewardRef = useRef(null);

    useEffect(() => {
      if (reactionConfig?.emoji && session?.isReactionEnabled)
        rewardRef.current?.rewardMe();
    }, [reactionConfig]);

    useEffect(() => {
      if (user) setUserMetadata(extractUserInfo(user));
    }, [user]);

    usePageVisibility((e) => {
      setIsTabActive(e.target.visibilityState === 'visible');
    });

    const handleSignal = useCallback(
      (s) => {
        if (s?.message?.type) {
          if (s.message.type === 'ban' && s.message?.uuid === uuid) {
            setIsUserBanned(s.message.value);
          }
        } else if (
          !openRightColumn &&
          isTabActive &&
          s?.message &&
          typeof s?.message === 'string' &&
          s?.message?.startsWith('react|')
        ) {
          showReaction(s.message.replace('react|', ''));
        }
      },
      [openRightColumn, isTabActive]
    );

    //Hooks
    const client = usePubNub();
    const { messages, occupancy, publish, publishSignal } = useChat({
      client,
      signalChannel: session?.isReactionEnabled && msgChannel,
      onSignal: handleSignal,
    });
    const reactionHelper = useReactions({ showLoader, hideLoader });

    //Functions
    const onButtonClick = (item) => {
      switch (item.type) {
        case 'box':
          //open box
          setOpenRightColumn(!openRightColumn);
          //close others
          setIsOpenReactions(false);
          setShowDialog(false);
          break;
        case 'hand':
          //open question dialog
          setShowDialog(true);
          //close others
          setOpenRightColumn(false);
          setIsOpenReactions(false);
          break;
        case 'document':
          //open survey dialog
          setShowDialog(true);
          //close others
          setOpenRightColumn(false);
          setIsOpenReactions(false);
          break;
        case 'heart':
          //open reactions
          setIsOpenReactions(!isOpenReactions);
          //close others
          setOpenRightColumn(false);
          break;

        default:
          break;
      }
    };

    const closeRightColumn = () => {
      setOpenRightColumn(false);
      setLastButtonSelected(null);
    };

    const closeDialog = () => {
      setShowDialog(false);
      setLastButtonSelected(null);
    };

    const handleQuestionSend = async (data) => {
      const input = {
        body: {
          type: 'sendQuestion',
          argsTC: session.argsTC,
          eventId: 'bto2021',
          sessionId: eventId,
          user: user,
          ...data,
        },
      };
      await fetchTC(
        input,
        () => {
          setShowDialog(false);
          setLastButtonSelected(null);
        },
        () => {
          setShowDialog(false);
          setLastButtonSelected(null);
        }
      );
    };

    const handleSurveySend = () => {
      setShowDialog(false);
      setLastButtonSelected(null);
    };

    const dialogContent = () => {
      switch (lastButtonSelected?.type) {
        case 'hand':
          return <AskAQuestion onSend={handleQuestionSend} />;
        case 'document':
          return (
            <Survey
              sessionId={session.id}
              //eventId={eventId}
              onSend={handleSurveySend}
              onSelect={setSuveryAnswerSelected}
              selected={surveyAnswerSelected}
            />
          );
        default:
          break;
      }
    };

    const onReactionClick = (data) => {
      publishSignal(`react|${data.id}`, async (res) => {
        if (!res.status.error) {
          try {
            await reactionHelper.create(
              {
                eventId: eventId,
                email: user.email,
                reaction: data.id,
                timestamp: new Date(),
              },
              false
            );
          } catch (e) {
            console.error(e);
          }
        }
      });
    };

    const showReaction = (data) => {
      const nextReactionConfig = { ...reactionConfig };

      for (const [, value] of Object.entries(
        constants.AgendaSessionLiveReactions
      )) {
        if (value.id === data) {
          nextReactionConfig.emoji = value.emoji;
          setReactionConfig(nextReactionConfig);
          break;
        }
      }
    };

    /* const handleJoin = () => {
    publish({
      type: 'sys',
      text: 'webinarJoin',
      userInfo: extractUserInfo(user),
    });
  };

  const handleLeave = () => {
    publish({
      type: 'sys',
      text: 'webinarLeave',
      userInfo: extractUserInfo(user),
    });
  }; */

    return (
      <div>
        {showControls && (
          <>
            <div
              style={{
                position: 'absolute',
                height: '50vh',
                width: '5rem',
                top: '25vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <LeftButtons
                id="myleftbuttons"
                onClick={onButtonClick}
                lastSelected={lastButtonSelected}
                setLastSelected={setLastButtonSelected}
                isOpenReactions={isOpenReactions}
                onReactionClick={onReactionClick}
                /* isReactionEnabled={session?.isReactionEnabled}*/
                isReactionEnabled={false}
                /*isPollingEnabled={session?.isPollingEnabled}*/
                isPollingEnabled={false}
                /*isQAEnabled={session?.isQAEnabled}*/
                isQAEnabled={false}
              />
            </div>
            <RightColumn open={openRightColumn} onClose={closeRightColumn}>
              <RightColumnTabs
                messages={messages}
                publish={publish}
                session={session}
                hasBeenBanned={isUserBanned}
                eventId={eventId}
              />
            </RightColumn>
            <CustomDialog
              fullScreen={true}
              open={showDialog}
              hideCancelButton
              hideAgreeButton
            >
              <CloseIconDialog viewBox="5 5 24 24" onClick={closeDialog} />
              {dialogContent()}
            </CustomDialog>
            <div
              style={{
                position: 'absolute',
                bottom: 200,
                right: 200,
              }}
            >
              <Reward
                ref={(ref) => {
                  rewardRef.current = ref;
                }}
                type={'emoji'}
                config={reactionConfig}
              >
                {' '}
              </Reward>
            </div>
          </>
        )}
        <TopControls
          peopleWatching={occupancy || 0}
          hideWatching={openRightColumn}
          isChatEnabled={session?.isChatEnabled}
        />
      </div>
    );
  }
);

Overlay.displayName = 'Overlay';

export { Overlay };
