import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import DoneIcon from '@material-ui/icons/Done';

import { theme } from '../theme';
import { styled } from '@material-ui/core/styles';

const StyledAgreeButton = styled(Button)({
  backgroundColor: theme.colors.redScale.secondary,
  justifyContent: 'center',
  color: theme.colors.greyScale.white,
  textTransform: 'lowercase',
});

const StyledCancelButton = styled(Button)({
  backgroundColor: theme.colors.greyScale.grey,
  justifyContent: 'center',
  color: theme.colors.greyScale.black,
  textTransform: 'lowercase',
});

const StyledDialogTitle = styled(DialogTitle)({
  paddingTop: 10,
  paddingLeft: 0,
  paddingBottom: 5,
  paddingRight: 20,
});

const StyledIconButton = styled(IconButton)({
  padding: 5,
});

const StyledDialogActions = styled(DialogActions)({
  padding: 0,
});

const StyledDialogContent = styled(DialogContent)({
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 20,
  paddingBottom: 20,
});

export const SdmDialog = ({
  open,
  onClose,
  title,
  children,
  disagreeText = 'cancel',
  onDisagree,
  agreeText = 'apply',
  onAgree,
  onAgreeDisabled,
  hideAgreeButton,
  hideCancelButton,
  customActions,
  ...rest
}) => {
  const handleOverflowBody = () => {
    const bodyStatus = document.body.getAttribute('style');
    if (bodyStatus.includes('overflow: hidden')) {
      document.body.style.overflow = 'visible';
      document.body.style.padding = 0;
    }
  };

  const handleClose = () => {
    handleOverflowBody();
    onClose && onClose();
  };

  const handleAgree = () => {
    onAgree && onAgree();
    handleClose();
  };

  const handleDisagree = () => {
    onDisagree && onDisagree();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 20,
          paddingBottom: 30,
        },
      }}
      {...rest}
    >
      <StyledDialogTitle id="alert-dialog-title">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{title}</div>
          <div
            tabIndex={0}
            role="button"
            onClick={handleDisagree}
            style={{ position: 'absolute', top: 0, right: 0 }}
          >
            <StyledIconButton>
              <CloseIcon />
            </StyledIconButton>
          </div>
        </div>
      </StyledDialogTitle>
      <Divider />
      <StyledDialogContent>
        {typeof children === 'string' ? (
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        ) : (
          children
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        {!customActions ? (
          <>
            {hideCancelButton ? null : (
              <StyledCancelButton
                onClick={handleDisagree}
                color={'primary'}
                startIcon={
                  <ReplayIcon style={{ fill: theme.colors.greyScale.black }} />
                }
              >
                {disagreeText}
              </StyledCancelButton>
            )}
            {hideAgreeButton ? null : (
              <StyledAgreeButton
                onClick={handleAgree}
                color="primary"
                disabled={onAgreeDisabled}
                autoFocus
                startIcon={
                  <DoneIcon style={{ fill: theme.colors.greyScale.white }} />
                }
              >
                {agreeText}
              </StyledAgreeButton>
            )}
          </>
        ) : (
          customActions
        )}
      </StyledDialogActions>
    </Dialog>
  );
};
