import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { SdmTypography } from './../../../components';
import { Speaker } from './sessionInfo/index';
import { theme } from '../../../theme';

const MainContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - 200px)',
});

const SectionContainer = styled('div')({
  marginTop: 10,
  display: 'flex',
  // width: '50%',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

const SessionTitle = styled(SdmTypography)({
  font: 'normal normal 700 20px/30px Poppins',
  [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
    font: 'normal normal 700 28px/42px Poppins',
  },
});

const SessionInfo = ({ i18n, session }) => {
  const [speakers, setSpeakers] = useState([]);

  useEffect(() => {
    if (!session || !session.speakers.length) return;

    const nextSpeakers = session.speakers.map((i) => ({
      ...i,
      displayName: `${i.givenName} ${i.familyName}`,
    }));

    setSpeakers(nextSpeakers);
  }, [session]);

  return (
    <MainContainer>
      {session?.title && (
        <SessionTitle margin={0}>{session.title}</SessionTitle>
      )}
      {session?.description && (
        <>
          <SdmTypography variant="h3" margin={0}>
            {i18n.description.label}
          </SdmTypography>
          <SectionContainer>
            <div dangerouslySetInnerHTML={{ __html: session?.description }} />
          </SectionContainer>
        </>
      )}
      {speakers.length && (
        <SdmTypography
          variant="h3"
          margin={0}
          style={{
            marginTop: '8px',
          }}
        >
          {i18n.speakers.label}
        </SdmTypography>
      )}
      <SectionContainer>
        <Grid container xs={4} zeroMinWidth>
          {speakers.map((speaker) => (
            <Box key={speaker.id} mt={1}>
              <Grid key={speaker.id} item xs={12}>
                <Speaker {...speaker} />
              </Grid>
            </Box>
          ))}
        </Grid>
      </SectionContainer>
    </MainContainer>
  );
};

export { SessionInfo };
