import React from 'react';
import { styled } from '@material-ui/core/styles';
import { theme } from '../../theme';

const IframeDivWrapper = styled('div')({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.colors.greyScale.black,
});

const IframeDiv = styled('div')({
  maxHeight: '100vh',
  width: '100vw',
  height: 'calc(100vw / 16 * 9)',
  maxWidth: '177vh',
});

const SessionVideo = ({ urlStreaming }) => {
  return (
    <IframeDivWrapper>
      <IframeDiv>
        <iframe
          src={urlStreaming}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          width="100%"
          height="100%"
          scrolling="no"
        />
      </IframeDiv>
    </IframeDivWrapper>
  );
};

export { SessionVideo };
