import React, { useState, useEffect, useContext } from 'react';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { useIntl } from 'react-intl';
import { SessionVideo, Overlay } from './webinar/index';
import { AuthContext, LoaderContext } from './../contexts';
import { useSessions } from './../utils';
import { NoSession } from './webinar/NoSession/NoSession';

const Webinar = () => {
  //Contexts
  const { setLoaderActive } = useContext(LoaderContext);
  const { failedAuth, user } = useContext(AuthContext);

  //Loader Function
  const showLoader = () => setLoaderActive(true);
  const hideLoader = () => setLoaderActive(false);

  //Hooks
  const sessionHelper = useSessions({ showLoader, hideLoader });
  const intl = useIntl();

  //State
  const [idEvento, setIdEvento] = useState();
  const [lingua, setLingua] = useState();
  const [session, setSession] = useState();
  const [client, setClient] = useState();
  const [clientData, setClientData] = useState({
    uuid: null,
    msgChannel: null,
  });
  const [sessionTimeout, setSessionTimeout] = useState();
  const [showSession, setShowSession] = useState(true);

  //Effects
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const lingua = query.get('lingua') ?? 'it';
    setIdEvento(query.get('idEvento'));
    setLingua(lingua);
  }, [location.search]);

  useEffect(() => {
    if (!idEvento) return;

    let lang;
    if (location.search) {
      lang = new URLSearchParams(location.search).get('lingua');
    }
    lang = lang ?? 'it';

    if (user && intl.locale && lang === intl.locale) {
      fetchData();
    }
  }, [intl.locale, user, idEvento]);

  useEffect(() => {
    if (idEvento && user?.email) {
      if (!client) {
        initChatClient();
      }
    }
  }, [idEvento, user]); //analyticsFeature

  const fetchData = async () => {
    await getAgendaSession();
  };

  useEffect(() => {
    if (sessionTimeout != null) {
      const interval = setTimeout(() => {
        setShowSession(false);
      }, sessionTimeout);
      return () => clearTimeout(interval);
    }
  }, [sessionTimeout]);

  const initChatClient = async () => {
    const newClientData = {
      uuid: user.email,
      msgChannel: idEvento,
    };
    setClientData(newClientData);

    const PUBNUB_PUBLISH_KEY = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
    const PUBNUB_SUBSCRIBE_KEY = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;

    const pubNubConfig = {
      publishKey: PUBNUB_PUBLISH_KEY,
      subscribeKey: PUBNUB_SUBSCRIBE_KEY,
      uuid: newClientData.uuid,
    };
    const _clientWithoutAuth = new PubNub(pubNubConfig);
    setClient(_clientWithoutAuth);
  };

  const getAgendaSession = async () => {
    try {
      const res = await sessionHelper.get(idEvento, lingua, user);

      const nextSession = {
        urlStreaming: res.linkStreaming /*+
          '&idSession=' +
          idEvento +
          '&idUser=' +
          user.idUser,*/,
        argsTC: encodeURIComponent(
          new URL(res.linkStreaming).searchParams.get('argsTC')
        ),
        isReactionEnabled: true,
        isQAEnabled: true,
        description: res?.descrizione,
        title: res?.titolo,
        speakers: res.speaker.map((s) => {
          return {
            image: s.foto,
            familyName: s.cognome,
            givenName: s.nome,
          };
        }),
      };

      setSession(nextSession);
      setSessionTimeout(res.timeoutFine);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      {failedAuth && <NoSession />}
      {!showSession && <NoSession layout={'sessionEnded'} />}
      {showSession && session && (
        <>
          <SessionVideo urlStreaming={session.urlStreaming} />
          {client && (
            <PubNubProvider client={client}>
              <Overlay
                msgChannel={clientData?.msgChannel}
                uuid={clientData?.uuid}
                session={session}
                hasBeenBanned={false}
                eventId={idEvento}
                showControls={showSession}
              />
            </PubNubProvider>
          )}
        </>
      )}
    </div>
  );
};

export { Webinar };
