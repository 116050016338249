import React, { createContext, useState, useEffect } from 'react';

import { API, Amplify } from 'aws-amplify';
import awsmobile from './../aws-exports';
Amplify.configure(awsmobile);
API.configure(awsmobile);

import { useLocation } from 'react-router-dom';

const AuthContext = createContext();

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const AuthContextProvider = ({ children }) => {
  // Hooks
  const query = useQuery();

  // State
  const [user, setUser] = useState();
  const [failedAuth, setFailedAuth] = useState(false);
  const [loginAgain, setLoginAgain] = useState(true);

  // Effect
  useEffect(() => {
    const token = query?.get('token');
    const idEvento = query?.get('idEvento');
    const body = {
      token,
      idEvento,
    };
    if (token && idEvento && loginAgain) {
      API.post('btoWrapper', '/getUser', {
        body,
      })
        .then(handleLogin)
        .catch(() => {
          setFailedAuth(true);
        });
    }
  }, [loginAgain]);

  useEffect(() => {
    const token = query?.get('token');
    const idEvento = query?.get('idEvento');
    const body = {
      token,
      idEvento,
    };

    if (user) {
      const handleBeforeunload = async (e) => {
        e.preventDefault();

        setTimeout(function () {
          setTimeout(function () {
            setLoginAgain(true);
          }, 1000);
        }, 100);

        await logout(body);
        e.returnValue = '';
      };

      window.addEventListener('beforeunload', handleBeforeunload);

      return () => {
        logout(body);
        window.removeEventListener('beforeunload', handleBeforeunload);
      };
    }
  }, [user]);

  // Functions
  const handleLogin = (user) => {
    const parsedPermissionField = parseInt(user?.permesso);

    if (!isNaN(parsedPermissionField) && parsedPermissionField === 1) {
      setUser(user);
      setLoginAgain(false);
      setFailedAuth(false);
    } else {
      throw 'Failed to login';
    }
  };

  const handleLogout = () => {
    setUser(null);
  };

  const logout = (body) => {
    if (user) {
      API.post('btoWrapper', '/setCheckout', {
        body,
      })
        .then(handleLogout)
        .catch(console.error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        failedAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, AuthContext };
