import React, { forwardRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from '@material-ui/core/styles';
import { theme } from './../theme';

const CustomCircularProgress = styled(CircularProgress)({
  padding: 8,
  color: theme.colors.redScale.primary,
});

/* eslint-disable */
export const SdmCircularLoader = forwardRef(({ style, ...rest }, ref) => (
  <CustomCircularProgress style={style} {...rest} ref={ref} />
));
/* eslint-enabled */
