import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { translation } from './../../utils';
import { SdmTypography, SdmTextField } from './../../components';
import { theme } from './../../theme';
import { makeStyles, styled } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';

const TransparentSdmTextField = styled(SdmTextField)({
  backgroundColor: 'transparent',
  borderBottom: `1px solid ${theme.colors.greyScale.white}`,
  borderRadius: 0,
  '& .MuiInput-input': {
    color: theme.colors.greyScale.white,
    font: 'normal normal bold 20px/36px Poppins',
  },
});

const formControlStyle = { width: 'calc(100%)' };

const RightArrow = styled('img')(({ question }) => ({
  maxHeight: '26px',
  width: 'auto',
  position: 'absolute',
  right: 0,
  bottom: '35px',
  cursor: question ? 'pointer' : 'normal',
}));

const AskAQuestionTitle = styled(SdmTypography)({
  font: 'normal normal 900 36px/24px Poppins',
  marginBottom: 30,
  [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
    font: 'normal normal 900 54px/36px Poppins',
    marginBottom: 0,
  },
});

const AskAQuestionText = styled(SdmTypography)({
  font: 'normal normal bold 16px/20px Poppins',
  [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
    font: 'normal normal bold 20px/36px Poppins',
  },
});

const AskAQuestion = ({ onSend }) => {
  //Hooks
  const intl = useIntl();
  const i18n = translation.webinar.askAQuestionDialog(intl);

  //State
  const [question, setQuestion] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);

  //Functions
  const handleQuestionChange = (data) => {
    setQuestion(data);
  };

  const toggleAnonymous = () => {
    setIsAnonymous(!isAnonymous);
  };

  const onSendQuestion = () => {
    const data = { question: question, isAnonymous: isAnonymous };
    onSend && onSend(data);
  };

  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: 1,
      width: 22,
      height: 22,
      border: '1px solid #fff',
      backgroundColor: 'transparent',
    },
    checkedIcon: {
      backgroundColor: 'transparent',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 12,
        height: 12,
        content: '""',
        backgroundRepeat: 'no-repeat',
        marginTop: '4px',
        marginLeft: '4px',
        backgroundImage:
          "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.735' height='11.595' viewBox='0 0 16.735 11.595' %3E%3Cpath id='Tracciato_30343' data-name='Tracciato 30343' d='M27.151,13.563l-1.368-1.273a.31.31,0,0,0-.218-.084h0a.3.3,0,0,0-.218.084l-9.481,8.641-3.45-3.122a.323.323,0,0,0-.435,0L10.6,19.062a.262.262,0,0,0,0,.4L14.95,23.4a1.439,1.439,0,0,0,.909.4,1.5,1.5,0,0,0,.9-.387h.008l10.39-9.45A.28.28,0,0,0,27.151,13.563Z' transform='translate(-10.505 -12.206)' fill='%23fff'/%3E%3C/svg%3E%0A\")",
      },
    },
  });

  const StyledCheckbox = (props) => {
    const classes = useStyles();

    return (
      <Checkbox
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        {...props}
      />
    );
  };

  return (
    <Grid
      container
      style={{
        color: theme.colors.greyScale.white,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        width: '50%',
      }}
    >
      <Grid item xs={12}>
        <AskAQuestionTitle textAlign="center">
          {i18n.text.title.label}
        </AskAQuestionTitle>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ paddingTop: '5%', paddingBottom: '1%', position: 'relative' }}
      >
        <TransparentSdmTextField
          formControlStyle={formControlStyle}
          onChange={(e) => handleQuestionChange(e.target.value)}
        />
        <RightArrow
          src={'/right-arrow.png'}
          alt="arrow"
          question={question}
          onClick={question ? onSendQuestion : undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <AskAQuestionText textAlign="center">
          {i18n.text.isAnonymousText.label}{' '}
          <StyledCheckbox onClick={toggleAnonymous} checked={isAnonymous} />
        </AskAQuestionText>
      </Grid>
    </Grid>
  );
};

export { AskAQuestion };
