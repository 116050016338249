import { styled } from '@material-ui/core/styles';
import React from 'react';
import { theme } from '../theme';

const Logo = ({ maxHeight }) => {
  const LogoImg = styled('img')({
    maxHeight: 40,
    width: 'auto',
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      maxHeight: 60,
    },
  });

  return <LogoImg src={'/bto-logo.png'} alt="logo" />;
};

export { Logo };
