import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SessionVideo, TopControls } from './webinar/index';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const WebinarBackup = () => {
  //Hooks
  const query = useQuery();

  //State
  const [urlStreaming, setUrlStreaming] = useState();
  const [lang, setLang] = useState('it');

  //Effects
  useEffect(() => {
    if (query) {
      // todo I've considered the "full" Streaming url here, aka it must also have session and user id.
      // If necessary, it shoud be build like appens inside Webminar.js getAgendaSession()
      setUrlStreaming(query.get('url-streaming'));

      setLang(query.get('lingua') ?? 'it');
    }
  }, [query]);

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <SessionVideo urlStreaming={urlStreaming} showSession={true} />
      <TopControls
        peopleWatching={0}
        hideWatching={true}
        isChatEnabled={false}
        exitDestinationOverride={`/agenda?lingua=${lang}`}
      />
    </div>
  );
};

export { WebinarBackup };
