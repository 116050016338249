import React from 'react';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { SdmTypography, SdmImage } from './../../../../components';

const SpeakerImage = styled(SdmImage)(() => ({
  maxWidth: '82px',
}));

const SpeakerContainer = styled('div')({
  display: 'flex',
});

const Speaker = ({ displayName, description, image }) => {
  return (
    <SpeakerContainer>
      <SpeakerImage src={image} alt="speaker-image" />
      <Box ml={1} alignSelf="center">
        <SdmTypography variant="text16Bold" margin={0}>
          {displayName}
        </SdmTypography>
        <SdmTypography variant="h5Regular" margin={0}>
          {description}
        </SdmTypography>
        {/* <SdmTypography variant="text" margin={0}>
          {`${start} - ${end}`}
        </SdmTypography> */}
      </Box>
    </SpeakerContainer>
  );
};

export { Speaker };
