import React, { useContext, useEffect, useState } from 'react';

import { styled } from '@material-ui/core/styles';
import { theme } from '../theme';
import { Logo } from '../components/Logo';
import { Grid } from '@material-ui/core';
import { SdmTypography } from '../components';
import { useIntl } from 'react-intl';
import { translation } from '../utils';
import { useSessions } from '../utils';
import { LoaderContext } from '../contexts';
import { Session } from './agenda/Session';
import { LocaleSwitch } from './agenda/LocaleSwitch';

const TopContainer = styled('div')({
  background: theme.colors.redScale.primary,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  display: 'flex',
  padding: '15px 0',
  maxHeight: 80,
});

const BodyContainer = styled('div')({
  background: theme.colors.greyScale.light,
  marginTop: 70,
  height: '100%',
});

const AgendaContainer = styled('div')({
  background: theme.colors.greyScale.white,
  marginTop: 15,
  padding: 30,
});

const SessioneContainer = styled(Grid)({
  borderBottom: `2px solid ${theme.colors.greyScale.light}`,
  padding: '30px 0',
  '&:first-child': {
    paddingTop: 0,
  },
  '&:last-child': {
    borderBottom: 'none',
    paddingBottom: 0,
  },
});

const Agenda = () => {
  const [sessions, setSessions] = useState([]);

  const intl = useIntl();
  const i18n = translation.webinar.session(intl);

  //Contexts
  const { setLoaderActive } = useContext(LoaderContext);

  //Loader Function
  const showLoader = () => setLoaderActive(true);
  const hideLoader = () => setLoaderActive(false);

  //Hooks
  const sessionHelper = useSessions({ showLoader, hideLoader });

  useEffect(() => {
    let lang;
    if (location.search) {
      lang = new URLSearchParams(location.search).get('lingua');
    }
    lang = lang ?? 'it';

    if (intl.locale && lang === intl.locale) {
      getAgenda(intl.locale);
    }
  }, [intl.locale]);

  const getAgenda = async (lingua) => {
    try {
      const res = await sessionHelper.getAgenda(lingua);

      setSessions(
        res.map((session) => {
          return {
            ...session,
            linkStreaming:
              session.linkStreaming /* + `&idUser=0&idSession=${session.id}`,*/,
          };
        })
        //.slice(0, 20) todo pagination?
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <TopContainer>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Logo maxHeight={50} />
          </Grid>
          <Grid item xs={1}>
            <LocaleSwitch />
          </Grid>
        </Grid>
      </TopContainer>
      <BodyContainer>
        <Grid container style={{ paddingTop: 60 }}>
          <Grid item xs={2} />
          <Grid container item xs={8}>
            <Grid item xs={12}>
              <SdmTypography variant="text32Bold" margin={0}>
                {i18n.agenda.text.title.label}
              </SdmTypography>
            </Grid>
            <Grid item xs={12}>
              <AgendaContainer>
                {sessions.map((session) => (
                  <SessioneContainer key={session.id} item xs={12}>
                    <Session key={session.id} session={session} />
                  </SessioneContainer>
                ))}
              </AgendaContainer>
            </Grid>
          </Grid>
        </Grid>
      </BodyContainer>
    </>
  );
};

export { Agenda };
