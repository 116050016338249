import { format } from 'date-fns';
import { enGB, it } from 'date-fns/locale';

const locales = { enGB, it };

export const formatLocaleDate = (date, formatStr = 'PP', locale = 'it') => {
  if (locale === 'en') {
    locale = 'enGB';
  }

  return format(date, formatStr, {
    locale: locales[locale],
  });
};
