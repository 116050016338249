import { API } from 'aws-amplify';

export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      userId
      sessionId
      text
      isAnonymous
      userFirstname
      userLastname
      createdAt
      updatedAt
    }
  }
`;

export const listQuestionByUserId = /* GraphQL */ `
  query ListQuestionByUserId(
    $userId: String
    $sessionId: String
    $nextToken: String
  ) {
    listQuestionByUserId(
      userId: $userId
      filter: { sessionId: { eq: $sessionId } }
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        sessionId
        text
        isAnonymous
        userFirstname
        userLastname
        createdAt
      }
      nextToken
    }
  }
`;

export const useQuestion = ({ showLoader, hideLoader }) => {
  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader?.();

      API.post(createQuestion, { input })
        .then((response) => {
          resolve(response.data.createQuestion);
        })
        .catch((e) => {
          console.error('create-question', e);
          reject();
        })
        .finally(() => useLoader && hideLoader?.());
    });

  const listByUserId = (userId, sessionId, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader?.();

      API.post(
        listQuestionByUserId,
        { userId: userId, sessionId: sessionId },
        true
      )
        .then((response) => {
          resolve(response.data.listQuestionByUserId.items);
        })
        .catch((e) => {
          console.error('list-question-by-userid', e);
          reject();
        })
        .finally(() => useLoader && hideLoader?.());
    });

  return { create, listByUserId };
};
