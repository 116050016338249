import React, { useContext, useState, useEffect } from 'react';

import { SdmTypography } from './../../../components';

import { styled } from '@material-ui/core/styles';

import format from 'date-fns/format';

import { Question } from './questionAnswers/index';

import { AuthContext, LoaderContext } from './../../../contexts';

import { fetchTC } from './../../../utils';

const MainContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - 200px)',
});

const QuestionsAnswers = ({ i18n, eventId, session }) => {
  //State
  const [questions, setQuestions] = useState([]);

  //Contexts
  const { user } = useContext(AuthContext);
  const { setLoaderActive } = useContext(LoaderContext);

  // Loader Functions
  const showLoader = () => setLoaderActive(true);
  const hideLoader = () => setLoaderActive(false);

  //Effects
  useEffect(() => {
    fetchQuestions();
  }, []);

  //Functions
  const fetchQuestions = async () => {
    const input = {
      body: {
        type: 'getQuestions',
        argsTC: session.argsTC,
        idEvent: 'bto2021',
        idSession: eventId,
        user: user,
      },
    };
    await fetchTC(
      input,
      (res) => {
        const parsedQuestion = res?.result?.map((i) => {
          const formatData = i.data ? format(new Date(i.data), 'HH:mm') : '-';

          return {
            id: i.id,
            time: formatData,
            question: i.question,
            givenName: i.name,
            familyName: i.surname,
            isMine: `${i.idUser}` === `${user.idUser}`,
          };
        });

        setQuestions(parsedQuestion);
      },
      null,
      null,
      showLoader,
      hideLoader
    );
  };

  return (
    <MainContainer>
      <SdmTypography variant="h4" margin={0}>
        {i18n.questions.label}
      </SdmTypography>
      {questions.map(
        ({ id, time, isMine, question, givenName, familyName }) => (
          <Question
            key={id}
            i18n={i18n.question}
            isMine={isMine}
            {...{ time, question, givenName, familyName }}
          />
        )
      )}
    </MainContainer>
  );
};

export { QuestionsAnswers };
