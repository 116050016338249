import React from 'react';
import { SdmIconAndTextButton, SdmTypography } from '../../components';
import { Grid } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import { styled } from '@material-ui/core/styles';
import { theme } from '../../theme';
import format from 'date-fns/format';
import { Speaker } from './Speaker';
import { useIntl } from 'react-intl';
import { formatLocaleDate, translation } from '../../utils';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Details = styled(SdmTypography)({
  borderRight: `1px solid ${theme.colors.greyScale.light}`,
  padding: '0 10px',
  '&:first-child': {
    paddingLeft: 0,
  },
  '&:last-child': {
    paddingRight: 0,
  },
});

const Session = ({ session }) => {
  const intl = useIntl();
  const i18n = translation.webinar.session(intl);

  const history = useHistory();

  const sessionClickHandler = () => {
    history.push(
      `/webinar-backup?url-streaming=${encodeURIComponent(
        session.linkStreaming
      )}&lingua=${intl.locale}`
    );
  };

  return (
    <>
      <SdmTypography
        variant="h4"
        margin={0}
        style={{ marginBottom: 10, textTransform: 'uppercase' }}
      >
        {formatLocaleDate(new Date(session.inizio), 'd LLLL', intl.locale)}
      </SdmTypography>
      {session.luogo && (
        <Grid container alignItems="center">
          <RoomIcon
            style={{
              color: theme.colors.redScale.light,
              height: 30,
              width: 'auto',
              paddingRight: 10,
            }}
          />

          <SdmTypography variant="text16Light" margin={0}>
            {session.luogo}
          </SdmTypography>
        </Grid>
      )}
      {/*
      <Grid container alignItems="center" style={{ marginTop: 10 }}>
        <Details variant="textLight" margin={0}>
          Internazionale
        </Details>
        <Details variant="textLight" margin={0}>
          Opening
        </Details>
        <Details variant="textLight" margin={0}>
          in Italiano
        </Details>
      </Grid>*/}
      <Grid container style={{ marginTop: 20 }}>
        <SdmTypography margin={0} variant="text">
          {format(new Date(session.inizio), 'HH:mm')} -{' '}
          {format(new Date(session.fine), 'HH:mm')}
        </SdmTypography>
      </Grid>
      <SdmTypography margin={0} variant="h4">
        {session.titolo}
      </SdmTypography>
      <SdmTypography
        margin={0}
        variant="textLight"
        style={{ margin: '20px 0' }}
      >
        <div dangerouslySetInnerHTML={{ __html: session?.descrizione }} />
      </SdmTypography>
      {session.speaker &&
        session.speaker.map((speaker) => (
          <Speaker key={speaker.nome + speaker.cognome} speaker={speaker} />
        ))}
      <SdmIconAndTextButton
        variant="redFill"
        text={i18n.agenda.buttons.enter.label}
        textColor={theme.colors.greyScale.white}
        isUpperCase={true}
        textVariant="textBold"
        iconPosition="after"
        onClick={sessionClickHandler}
        style={{
          borderRadius: 5,
          margin: '30px 0 0 0',
          minWidth: 160,
          background: theme.colors.redScale.light,
        }}
      >
        <ChevronRightIcon />
      </SdmIconAndTextButton>
    </>
  );
};

export { Session };
