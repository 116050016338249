import React from 'react';
import { useIntl } from 'react-intl';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { SdmIconAndTextButton, SdmTypography } from '../../../components';
import { theme } from '../../../theme';
import translation from '../../../utils/translation';

const SessionEnded = () => {
  const intl = useIntl();
  const i18n = translation.webinar.session(intl);

  return (
    <>
      <SdmTypography
        style={{
          font: 'normal normal 300 31px/22px Poppins',
          lineHeight: 1.1,
        }}
      >
        {i18n.sessionEnded.text.firstLine.label}
      </SdmTypography>
      <SdmTypography
        style={{
          font: 'normal normal 300 31px/22px Poppins',
          lineHeight: 1.1,
          marginTop: 15,
        }}
      >
        {i18n.sessionEnded.text.secondLine.label}
      </SdmTypography>
      <SdmIconAndTextButton
        variant="yellowBorder"
        text={i18n.sessionEnded.buttons.allSessions.label}
        textColor={theme.colors.greyScale.white}
        isUpperCase={true}
        textVariant="textBold"
        onClick={() => {
          window.location.href = process.env.REACT_APP_WEBINAR_BACK_REDIRECT;
        }}
        style={{ borderRadius: 5, marginTop: 30 }}
      >
        <ArrowForwardIcon />
      </SdmIconAndTextButton>
    </>
  );
};

export { SessionEnded };
