import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const fontFamilies = {
  roboto: 'Roboto',
  robotoCondensed: 'Roboto Condensed',
  sienaRegular: 'FSSiena-Regular',
  poppins: 'Poppins',
};

const fontWeights = {
  light: 'fontWeightLight',
  regular: 'fontWeightRegular',
  medium: 'fontWeightMedium',
  bold: 'fontWeightBold',
};

const variants = {
  pageTitle: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.regular,
    fontSize: '1.75rem',
  },
  h1: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '1.75rem',
  },
  h1Regular: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.regular,
    fontSize: '1.75rem',
  },
  h2: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '1.3125rem',
  },
  h2Regular: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.regular,
    fontSize: '1.3125rem',
  },
  h3: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '1.125rem',
  },
  h4: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '1.125rem',
  },
  h4Regular: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.regular,
    fontSize: '1.125rem',
  },
  h5: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '0.875rem',
  },
  h5Regular: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.regular,
    fontSize: '0.875rem',
  },
  h6: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '0.875rem',
  },
  text32: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.regular,
    fontSize: '2rem',
  },
  text32Bold: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '2rem',
  },
  text16: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.regular,
    fontSize: '1rem',
  },
  text16Bold: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '1rem',
  },
  text16Light: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.light,
    fontSize: '1rem',
  },
  text: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.regular,
    fontSize: '0.875rem',
  },
  textBold: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '0.875rem',
  },
  textLight: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.light,
    fontSize: '0.875rem',
  },
  columnHeader: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '0.875rem',
  },
  cardHeader: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '1.125rem',
  },
  cardContent: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.regular,
    fontSize: '1.125rem',
  },
  formError: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.regular,
    fontSize: '0.875rem',
    color: 'red',
    marginTop: 0,
  },
  feRegistrationTitle: {
    fontFamily: fontFamilies.poppins,
    fontWeight: fontWeights.bold,
    fontSize: '2.5rem',
    marginTop: 0,
  },
};

const ellipsisStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const SdmTypography = ({
  variant,
  textAlign = 'left',
  children,
  margin = 1,
  ellipsis,
  ...rest
}) => (
  <Typography {...rest} component="div">
    <Box
      textAlign={textAlign}
      {...variants[variant]}
      m={margin}
      style={ellipsis && ellipsisStyle}
    >
      {children}
    </Box>
  </Typography>
);

export { SdmTypography };
