import { useEffect, useRef, useCallback } from 'react';

const createHandlerSetter = (handlerValue) => {
  const handlerRef = useRef(handlerValue);

  // since useRef accepts an initial-value only, this is needed to make sure
  handlerRef.current = handlerValue;

  const setHandler = useCallback((nextCallback) => {
    handlerRef.current = nextCallback;
  });

  return [handlerRef, setHandler];
};

const useChat = ({ client, signalChannel, onSignal }) => {
  // set signal callback
  const [onSignalRef, setOnSignalRef] = createHandlerSetter(onSignal);

  useEffect(() => {
    setOnSignalRef(onSignal);
  }, [onSignal]);

  useEffect(() => {
    if (!signalChannel) return;
    const listener = {
      signal: (s) => {
        s && onSignalRef?.current && onSignalRef?.current(s);
      },
    };

    const timetoken = pubNubTimeToken();
    client.addListener(listener);
    try {
      client.subscribe({
        channels: [signalChannel],
        timetoken: timetoken,
      });
    } catch (error) {
      console.log(error);
    }

    return () => {
      client.removeListener(listener);
      client.unsubscribe({ channels: [signalChannel] });
    };
  }, [signalChannel]);

  const pubNubTimeToken = () => {
    const timetoken = Math.floor(Date.now() * 10000);
    return timetoken;
  };

  const publishSignal = (text, callback) => {
    client.signal(
      {
        channel: [signalChannel],
        message: text,
      },
      (status, response) => {
        callback?.({ status, response });
      }
    );
  };

  return {
    publishSignal,
  };
};

export { useChat };
