import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { theme } from '../../../theme';
import { TopControls } from '../TopControls';
import { SessionEnded } from './SessionEnded';
import { NoAuth } from './NoAuth';

const SessionEndedDiv = styled('div')({
  color: theme.colors.greyScale.white,
  background: theme.colors.greyScale.black,
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const NoSession = ({ layout }) => {
  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <SessionEndedDiv>
        <Box ml={30}>
          {layout === 'sessionEnded' ? <SessionEnded /> : <NoAuth />}
        </Box>
      </SessionEndedDiv>
      <TopControls
        peopleWatching={0}
        hideWatching={true}
        isChatEnabled={false}
      />
    </div>
  );
};

export { NoSession };
