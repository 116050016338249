import { API } from 'aws-amplify';

export const fetchTC = async (
  input,
  onResponse,
  onError,
  ignoreLoading,
  showLoader,
  hideLoader
) => {
  !ignoreLoading && showLoader?.();
  try {
    const apiName = 'tcInterface';
    const path = '/tc';
    const res = await API.post(apiName, path, input);

    onResponse?.(res);
    !ignoreLoading && hideLoader?.();
  } catch (e) {
    console.error('👎 error - get question', e);
    onError?.(e);
    !ignoreLoading && hideLoader?.();
  }
};
