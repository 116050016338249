import { API, Amplify } from 'aws-amplify';
import awsmobile from './../aws-exports';
Amplify.configure(awsmobile);
API.configure(awsmobile);

export const useSessions = ({ showLoader, hideLoader }) => {
  const get = (idEvento, lingua, user, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader?.();

      const body = {
        user: user,
        idEvento: idEvento,
        lingua: lingua,
      };

      API.post('virtualEventInterface', '/getEvento', {
        body,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          console.error('get-session-error: ', e);
          reject();
        })
        .finally(() => useLoader && hideLoader?.());
    });

  const getAgenda = (lingua, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader?.();

      API.get('virtualEventInterface', `/getAgenda?lingua=${lingua}`, {})
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          console.error('get-agenda-error: ', e);
          reject();
        })
        .finally(() => useLoader && hideLoader?.());
    });

  return { get, getAgenda };
};
