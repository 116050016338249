import React from 'react';

import { SdmTypography, SdmIconAndTextButton } from './../../components';

import { theme } from './../../theme';
import { styled } from '@material-ui/core/styles';
import { translation } from '../../utils';
import { useIntl } from 'react-intl';
// import { Logo } from '../../components/Logo';

const REACT_APP_WEBINAR_BACK_REDIRECT =
  process.env.REACT_APP_WEBINAR_BACK_REDIRECT;

const peopleWatchingIconStyle = {
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  marginLeft: 10,
  marginRight: 10,
  pointerEvents: 'none',
};

const ControlContainer = styled('div')({
  color: theme.colors.greyScale.white,
  position: 'absolute',
  top: 25,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const LeftTopControlsContainer = styled('div')({
  marginLeft: 25,
});

const RightTopControlsContainer = styled('div')({
  display: 'flex',
  marginRight: 25,
});

const ExitButton = styled(SdmIconAndTextButton)({
  borderRadius: 4,
  background: '#FE0C4E',
  [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
    minWidth: 162,
    minHeight: 49,
  },
});

const TopControlsComponent = ({ exitDestinationOverride }) => {
  const intl = useIntl();
  const i18n = translation.webinar.topControls(intl);

  return (
    <ControlContainer>
      <LeftTopControlsContainer></LeftTopControlsContainer>
      {/* <Logo /> */}
      <RightTopControlsContainer>
        <ExitButton
          variant="redFill"
          onClick={() => {
            window.location.href =
              exitDestinationOverride || REACT_APP_WEBINAR_BACK_REDIRECT;
          }}
          text={
            <SdmTypography
              style={{
                color: theme.colors.greyScale.white,
                textTransform: 'uppercase',
                fontWeight: 'regular',
              }}
            >
              {i18n.buttons.exit.label}
            </SdmTypography>
          }
        />
      </RightTopControlsContainer>
    </ControlContainer>
  );
};

const TopControls = React.memo(TopControlsComponent);

export { TopControls };
