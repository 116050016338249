const scalingFactor = 8;

export const theme = {
  spacing: (value) => value * scalingFactor,
  breakpoints: { sm: 576, md: 768, lg: 992 },
  colors: {
    primary: {
      yellow: '#FFD400',
    },
    secondary: {},
    greyScale: {
      white: '#FFFFFF',
      light: '#F3F3F3',
      grey: '#D8D8D8',
      darkGray: '#979797',
      backgroundGrey: '#191919',
      black: '#000000',
    },
    dark: {},
    redScale: {
      primary: '#E30613',
      secondary: '#E2001A',
      dark: '#B40014',
      light: '#FF0050',
    },
  },
};
