import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import { SdmTypography } from '.';
import { theme } from '../theme';
import { styled } from '@material-ui/core/styles';

const variants = {
  black: {
    border: '2px solid ' + theme.colors.greyScale.black,
    color: theme.colors.greyScale.grey,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
  red: {
    border: '2px solid ' + theme.colors.redScale.secondary,
    color: theme.colors.greyScale.white,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
  blackTissueFill: {
    color: theme.colors.greyScale.white,
    background: 'rgba(0,0,0,0.3)',
    '&:hover': {
      background: 'rgba(0,0,0,0.6) !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.white,
      background: 'rgba(0,0,0,0.3)',
      opacity: 0.23,
    },
  },
  redFill: {
    background: theme.colors.redScale.primary,
    color: theme.colors.greyScale.white,
    '&:hover': {
      color: theme.colors.greyScale.white,
      background: theme.colors.redScale.dark,
    },
    '&:disabled': {
      background: `${theme.colors.redScale.primary} !important`,
      color: theme.colors.greyScale.white,
      opacity: 0.23,
    },
  },
  yellowBorder: {
    border: `1px solid ${theme.colors.primary.yellow}`,
    color: theme.colors.greyScale.white,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
  none: {
    border: '2px solid transparent',
    color: theme.colors.greyScale.grey,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.grey,
      background: 'transparent !important',
      opacity: 0.23,
    },
  },
};

const CustomIconButton = styled(IconButton)(
  ({ variant = 'red', small = false }) => ({
    borderRadius: 0,
    height: small ? 30 : 40,
    margin: 4,
    padding: 12,
    cursor: 'pointer',
    ...variants[variant],
  })
);

const CustomButton = (
  {
    text,
    textColor,
    isUpperCase = false,
    children,
    iconPosition = 'before',
    ...rest
  },
  ref
) => (
  <CustomIconButton ref={ref} {...rest} disableRipple disableFocusRipple>
    {iconPosition === 'before' && <>{children}</>}
    {text && (
      <SdmTypography
        variant={rest.textVariant ? rest.textVariant : 'text'}
        style={{
          color: textColor ? textColor : theme.colors.greyScale.black,
          textTransform: isUpperCase ? 'uppercase' : 'none',
        }}
      >
        {text}
      </SdmTypography>
    )}
    {iconPosition === 'after' && <>{children}</>}
  </CustomIconButton>
);

CustomButton.displayName = 'SdmIconAndTextButton';

export const SdmIconAndTextButton = React.forwardRef(CustomButton);
