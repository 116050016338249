import React from 'react';

import { SdmTypography } from './../../../components';

import { theme } from './../../../theme';
import { styled } from '@material-ui/core/styles';

const StyledTabHeader = styled('div')(() => ({
  color: theme.colors.greyScale.white,
  cursor: 'pointer',
  marginRight: '8px',
}));

const StyledTypography = styled(SdmTypography)(({ selected }) => ({
  fontFamily: 'poppins',
  opacity: selected ? 1 : 0.58,
  fontWeight: selected ? 400 : 300,
  fontSize: '28px',
}));

const TabHeader = ({ name, isSelected, onClickTabHeader }) => {
  return (
    <StyledTabHeader onClick={onClickTabHeader}>
      <StyledTypography textAlign="left" selected={isSelected}>
        {name}
      </StyledTypography>
    </StyledTabHeader>
  );
};

export { TabHeader };
