/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://gfxbeju2yjcwdgfuqomzehf4my.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-l2a3ehn3xnethatdphilcfhk6y",
    "aws_cloud_logic_custom": [
        {
            "name": "virtualEventInterface",
            "endpoint": "https://yajfwnao71.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "btoWrapper",
            "endpoint": "https://n4wyjltxf1.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "tcInterface",
            "endpoint": "https://ki8zo01egb.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "staticData",
            "endpoint": "https://q4n5yz4ch5.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
