import React from 'react';
import { Grid } from '@material-ui/core';
import { SdmTypography } from '../../components';
import { theme } from '../../theme';

const Speaker = ({ speaker }) => {
  return (
    <Grid container xs={12} alignItems="center">
      <SdmTypography margin={0} variant="text">
        {speaker.nome} {speaker.cognome}
      </SdmTypography>
      {speaker.ruolo && (
        <>
          <SdmTypography margin={0} variant="text">
            ,
          </SdmTypography>
          <SdmTypography
            margin={0}
            variant="textBold"
            style={{ color: theme.colors.redScale.light, letterSpacing: -1 }}
          >
            &nbsp;{speaker.ruolo}
          </SdmTypography>
        </>
      )}
    </Grid>
  );
};

export { Speaker };
