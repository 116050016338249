import React, { useState, useRef } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

import { theme } from '../theme';

export const SdmImage = ({ src, alt, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const imgRef = useRef();

  return (
    <div style={{ position: 'relative' }}>
      <Fade in={!loading}>
        <img
          ref={imgRef}
          src={src}
          alt={alt}
          {...rest}
          onLoad={() => setLoading(false)}
          onError={() => {
            setLoading(false);
            setError(true);
          }}
        />
      </Fade>
      {!error && loading && (
        <CircularProgress
          style={{
            margin: 'auto',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            color: theme.colors.redScale.primary,
          }}
        />
      )}
    </div>
  );
};
