import React from 'react';

import { useIntl } from 'react-intl';

const LocaleButton = ({ locale }) => {
  const intl = useIntl();
  const isSelected = intl?.locale?.toUpperCase?.() === locale;

  const onClickButton = () => {
    window.location.href = `/agenda?lingua=${locale.toLowerCase()}`;
  };

  return (
    <div
      style={{
        fontFamily: 'Poppins',
        cursor: 'pointer',
        color: isSelected ? 'rgb(128, 128, 128)' : 'white',
        fontWeight: 600,
      }}
      onClick={onClickButton}
    >
      {locale}
    </div>
  );
};

const LocaleSwitch = () => {
  return (
    <div style={{ color: 'white', display: 'flex', gap: 5 }}>
      <LocaleButton locale="IT" />
      {' | '}
      <LocaleButton locale="EN" />
    </div>
  );
};

export { LocaleSwitch };
